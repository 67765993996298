/* eslint-disable */
import SelectPure from 'select-pure';

const multiSelectDropDownCss = {
  select: 'select-pure__select',
  dropdownShown: 'select-pure__select--opened',
  multiselect: 'select-pure__select--multiple',
  label: 'select-pure__label',
  placeholder: 'select-pure__placeholder',
  dropdown: 'select-pure__options',
  option: 'select-pure__option',
  autocompleteInput: 'select-pure__autocomplete',
  selectedLabel: 'select-pure__selected-label',
  selectedOption: 'select-pure__option--selected',
  placeholderHidden: 'select-pure__placeholder--hidden',
  optionHidden: 'select-pure__option--hidden'
};

export const createNiveauDropDown = (dropdown, niveau, meerVoudigeSelectie) => {
  let niveauDropDown = '';

  if (
    niveau === undefined ||
    niveau === 'undefined' ||
    niveau.trim().length === 0
  ) {
    niveauDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: '1ste kleuterklas',
          value: '1ste kleuterklas'
        },
        {
          label: '2de kleuterklas',
          value: '2de kleuterklas'
        },
        {
          label: '3de kleuterklas',
          value: '3de kleuterklas'
        },
        {
          label: '1ste leerjaar',
          value: '1ste leerjaar'
        },
        {
          label: '2de leerjaar',
          value: '2de leerjaar'
        },
        {
          label: '3de leerjaar',
          value: '3de leerjaar'
        },
        {
          label: '4de leerjaar',
          value: '4de leerjaar'
        },
        {
          label: '5de leerjaar',
          value: '5de leerjaar'
        },
        {
          label: '6de leerjaar',
          value: '6de leerjaar'
        },
        {
          label: '1ste middelbaar',
          value: '1ste middelbaar'
        },
        {
          label: '2de middelbaar',
          value: '2de middelbaar'
        },
        {
          label: '3de middelbaar',
          value: '3de middelbaar'
        },
        {
          label: '4de middelbaar',
          value: '4de middelbaar'
        },
        {
          label: '5de middelbaar',
          value: '5de middelbaar'
        },
        {
          label: '6de middelbaar',
          value: '6de middelbaar'
        },
        {
          label: '7de middelbaar',
          value: '7de middelbaar'
        }
      ],
      multiple: meerVoudigeSelectie,
      autocomplete: true,
      icon: 'icon-close',
      placeholder: 'Niveau',
      classNames: multiSelectDropDownCss
    });
  } else {
    const niveauValues = niveau.split(',');
    niveauDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: '1ste kleuterklas',
          value: '1ste kleuterklas'
        },
        {
          label: '2de kleuterklas',
          value: '2de kleuterklas'
        },
        {
          label: '3de kleuterklas',
          value: '3de kleuterklas'
        },
        {
          label: '1ste leerjaar',
          value: '1ste leerjaar'
        },
        {
          label: '2de leerjaar',
          value: '2de leerjaar'
        },
        {
          label: '3de leerjaar',
          value: '3de leerjaar'
        },
        {
          label: '4de leerjaar',
          value: '4de leerjaar'
        },
        {
          label: '5de leerjaar',
          value: '5de leerjaar'
        },
        {
          label: '6de leerjaar',
          value: '6de leerjaar'
        },
        {
          label: '1ste middelbaar',
          value: '1ste middelbaar'
        },
        {
          label: '2de middelbaar',
          value: '2de middelbaar'
        },
        {
          label: '3de middelbaar',
          value: '3de middelbaar'
        },
        {
          label: '4de middelbaar',
          value: '4de middelbaar'
        },
        {
          label: '5de middelbaar',
          value: '5de middelbaar'
        },
        {
          label: '6de middelbaar',
          value: '6de middelbaar'
        },
        {
          label: '7de middelbaar',
          value: '7de middelbaar'
        }
      ],
      value: niveauValues,
      multiple: meerVoudigeSelectie,
      autocomplete: true,
      icon: 'icon-close',
      placeholder: 'Niveau',
      classNames: multiSelectDropDownCss
    });
  }

  return niveauDropDown;
};

export const createRolDropDown = (dropdown, role) => {
  const rolDropDown = new SelectPure(dropdown, {
    options: [
      {
        label: 'Admin',
        value: 'admin'
      },
      {
        label: 'User',
        value: 'user'
      }
    ],
    value: role,
    icon: 'icon-close',
    placeholder: 'Rol',
    classNames: multiSelectDropDownCss
  });

  return rolDropDown;
};

export const createHandleidingDropDown = (dropdown, handleiding) => {
  const handleidingDropDown = new SelectPure(dropdown, {
    options: [
      {
        label: 'Handleiding aanwezig',
        value: 'true'
      },
      {
        label: 'Handleiding afwezig',
        value: 'false'
      }
    ],
    value: handleiding,
    icon: 'icon-close',
    placeholder: 'Handleiding',
    classNames: multiSelectDropDownCss
  });

  return handleidingDropDown;
};

export const createLocatieDropDown = (dropdown, location) => {
  let locatieDropDown = '';
  if (location !== undefined) {
    locatieDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'De Sleutel - RKJ',
          value: 'De Sleutel - RKJ'
        },
        {
          label: 'Guislain - De Steiger',
          value: 'Guislain - De Steiger'
        },
        {
          label: 'Guislain - Fioretti',
          value: 'Guislain - Fioretti'
        },
        {
          label: 'Karus - Branding',
          value: 'Karus - Branding'
        },
        {
          label: 'Karus - Dageraad',
          value: 'Karus - Dageraad'
        },
        {
          label: 'Karus - DAM',
          value: 'Karus - DAM'
        },
        {
          label: 'Karus - JOVO',
          value: 'Karus - JOVO'
        },
        {
          label: 'Karus - LG1',
          value: 'Karus - LG1'
        },
        {
          label: 'Karus - LG2',
          value: 'Karus - LG2'
        },
        {
          label: 'Karus - LG3',
          value: 'Karus - LG3'
        },
        {
          label: 'Karus - LG4',
          value: 'Karus - LG4'
        },
        {
          label: 'Karus - Kaap Klas 1',
          value: 'Karus - Kaap Klas 1'
        },
        {
          label: 'Karus - Kaap Klas 2',
          value: 'Karus - Kaap Klas 2'
        },
        {
          label: 'Karus - Kaap Klas 3',
          value: 'Karus - Kaap Klas 3'
        },
        {
          label: 'Karus - Kaap Klas 4',
          value: 'Karus - Kaap Klas 4'
        },
        {
          label: 'Karus - Kaap Klas 5',
          value: 'Karus - Kaap Klas 5'
        },
        {
          label: 'Karus - Kaap Leraarskamer',
          value: 'Karus - Kaap Leraarskamer'
        },
        {
          label: 'Karus - Kaap Knutselkast',
          value: 'Karus - Kaap Knutselkast'
        },
        {
          label: 'Karus - Getijden Klas 1',
          value: 'Karus - Getijden Klas 1'
        },
        {
          label: 'Karus - Getijden Klas 2',
          value: 'Karus - Getijden Klas 2'
        },
        {
          label: 'Karus - Getijden Klas 3',
          value: 'Karus - Getijden Klas 3'
        },
        {
          label: 'Karus - Getijden Klas 4',
          value: 'Karus - Getijden Klas 4'
        },
        {
          label: 'Karus - Getijden Klas 5',
          value: 'Karus - Getijden Klas 5'
        },
        {
          label: 'Karus - Getijden Project',
          value: 'Karus - Getijden Project'
        },
        {
          label: 'Karus - Getijden Praktijk',
          value: 'Karus - Getijden Praktijk'
        },
        {
          label: 'Karus - Getijden STEM',
          value: 'Karus - Getijden STEM'
        },
        {
          label: 'Karus - Getijden Leraarskamer',
          value: 'Karus - Getijden Leraarskamer'
        },
        {
          label: 'Karus - Getijden Klastitularis',
          value: 'Karus - Getijden Klastitularis'
        },
        {
          label: 'Karus - Getijden Gangkast',
          value: 'Karus - Getijden Gangkast'
        },
        {
          label: 'Karus - Branding Praktijk',
          value: 'Karus - Branding Praktijk'
        },
        {
          label: 'Karus - Branding Klas',
          value: 'Karus - Branding Klas'
        },
        {
          label: 'Sint-Lucas',
          value: 'Sint-Lucas'
        },
        {
          label: 'UZ - 1K5',
          value: 'UZ - 1K5'
        },
        {
          label: 'UZ - 4K12D',
          value: 'UZ - 4K12D'
        },
        {
          label: 'UZ - 5K12D',
          value: 'UZ - 5K12D'
        },
        {
          label: 'UZ - ES',
          value: 'UZ - ES'
        },
        {
          label: 'UZ - K7',
          value: 'UZ - K7'
        },
        {
          label: 'UZ - KJP',
          value: 'UZ - KJP'
        },
        {
          label: 'UZ - KRC',
          value: 'UZ - KRC'
        }
      ],
      value: location.trim(),
      icon: 'icon-close',
      placeholder: 'Locatie',
      classNames: multiSelectDropDownCss
    });
  } else {
    locatieDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'De Sleutel - RKJ',
          value: 'De Sleutel - RKJ'
        },
        {
          label: 'Guislain - De Steiger',
          value: 'Guislain - De Steiger'
        },
        {
          label: 'Guislain - Fioretti',
          value: 'Guislain - Fioretti'
        },
        {
          label: 'Karus - Branding',
          value: 'Karus - Branding'
        },
        {
          label: 'Karus - Dageraad',
          value: 'Karus - Dageraad'
        },
        {
          label: 'Karus - DAM',
          value: 'Karus - DAM'
        },
        {
          label: 'Karus - JOVO',
          value: 'Karus - JOVO'
        },
        {
          label: 'Karus - LG1',
          value: 'Karus - LG1'
        },
        {
          label: 'Karus - LG2',
          value: 'Karus - LG2'
        },
        {
          label: 'Karus - LG3',
          value: 'Karus - LG3'
        },
        {
          label: 'Karus - LG4',
          value: 'Karus - LG4'
        },
        {
          label: 'Karus - Kaap Klas 1',
          value: 'Karus - Kaap Klas 1'
        },
        {
          label: 'Karus - Kaap Klas 2',
          value: 'Karus - Kaap Klas 2'
        },
        {
          label: 'Karus - Kaap Klas 3',
          value: 'Karus - Kaap Klas 3'
        },
        {
          label: 'Karus - Kaap Klas 4',
          value: 'Karus - Kaap Klas 4'
        },
        {
          label: 'Karus - Kaap Klas 5',
          value: 'Karus - Kaap Klas 5'
        },
        {
          label: 'Karus - Kaap Leraarskamer',
          value: 'Karus - Kaap Leraarskamer'
        },
        {
          label: 'Karus - Kaap Knutselkast',
          value: 'Karus - Kaap Knutselkast'
        },
        {
          label: 'Karus - Getijden Klas 1',
          value: 'Karus - Getijden Klas 1'
        },
        {
          label: 'Karus - Getijden Klas 2',
          value: 'Karus - Getijden Klas 2'
        },
        {
          label: 'Karus - Getijden Klas 3',
          value: 'Karus - Getijden Klas 3'
        },
        {
          label: 'Karus - Getijden Klas 4',
          value: 'Karus - Getijden Klas 4'
        },
        {
          label: 'Karus - Getijden Klas 5',
          value: 'Karus - Getijden Klas 5'
        },
        {
          label: 'Karus - Getijden Project',
          value: 'Karus - Getijden Project'
        },
        {
          label: 'Karus - Getijden Praktijk',
          value: 'Karus - Getijden Praktijk'
        },
        {
          label: 'Karus - Getijden STEM',
          value: 'Karus - Getijden STEM'
        },
        {
          label: 'Karus - Getijden Leraarskamer',
          value: 'Karus - Getijden Leraarskamer'
        },
        {
          label: 'Karus - Getijden Klastitularis',
          value: 'Karus - Getijden Klastitularis'
        },
        {
          label: 'Karus - Getijden Gangkast',
          value: 'Karus - Getijden Gangkast'
        },
        {
          label: 'Karus - Branding Praktijk',
          value: 'Karus - Branding Praktijk'
        },
        {
          label: 'Karus - Branding Klas',
          value: 'Karus - Branding Klas'
        },
        {
          label: 'Sint-Lucas',
          value: 'Sint-Lucas'
        },
        {
          label: 'UZ - 1K5',
          value: 'UZ - 1K5'
        },
        {
          label: 'UZ - 4K12D',
          value: 'UZ - 4K12D'
        },
        {
          label: 'UZ - 5K12D',
          value: 'UZ - 5K12D'
        },
        {
          label: 'UZ - ES',
          value: 'UZ - ES'
        },
        {
          label: 'UZ - K7',
          value: 'UZ - K7'
        },
        {
          label: 'UZ - KJP',
          value: 'UZ - KJP'
        },
        {
          label: 'UZ - KRC',
          value: 'UZ - KRC'
        }
      ],
      icon: 'icon-close',
      placeholder: 'Locatie',
      classNames: multiSelectDropDownCss
    });
  }
  return locatieDropDown;
};

export const createDepartmentDropDown = (dropdown, userDepartment) => {
  let departmentDropDown = '';

  if (
    userDepartment === undefined ||
    userDepartment === 'undefined' ||
    userDepartment.trim().length === 0
  ) {
    departmentDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'De Sleutel - RKJ',
          value: 'De Sleutel - RKJ'
        },
        {
          label: 'Guislain - De Steiger',
          value: 'Guislain - De Steiger'
        },
        {
          label: 'Guislain - Fioretti',
          value: 'Guislain - Fioretti'
        },
        {
          label: 'Karus - Branding',
          value: 'Karus - Branding'
        },
        {
          label: 'Karus - Dageraad',
          value: 'Karus - Dageraad'
        },
        {
          label: 'Karus - DAM',
          value: 'Karus - DAM'
        },
        {
          label: 'Karus - JOVO',
          value: 'Karus - JOVO'
        },
        {
          label: 'Karus - LG1',
          value: 'Karus - LG1'
        },
        {
          label: 'Karus - LG2',
          value: 'Karus - LG2'
        },
        {
          label: 'Karus - LG3',
          value: 'Karus - LG3'
        },
        {
          label: 'Karus - LG4',
          value: 'Karus - LG4'
        },
        {
          label: 'Karus - Kaap Klas 1',
          value: 'Karus - Kaap Klas 1'
        },
        {
          label: 'Karus - Kaap Klas 2',
          value: 'Karus - Kaap Klas 2'
        },
        {
          label: 'Karus - Kaap Klas 3',
          value: 'Karus - Kaap Klas 3'
        },
        {
          label: 'Karus - Kaap Klas 4',
          value: 'Karus - Kaap Klas 4'
        },
        {
          label: 'Karus - Kaap Klas 5',
          value: 'Karus - Kaap Klas 5'
        },
        {
          label: 'Karus - Kaap Leraarskamer',
          value: 'Karus - Kaap Leraarskamer'
        },
        {
          label: 'Karus - Kaap Knutselkast',
          value: 'Karus - Kaap Knutselkast'
        },
        {
          label: 'Karus - Getijden Klas 1',
          value: 'Karus - Getijden Klas 1'
        },
        {
          label: 'Karus - Getijden Klas 2',
          value: 'Karus - Getijden Klas 2'
        },
        {
          label: 'Karus - Getijden Klas 3',
          value: 'Karus - Getijden Klas 3'
        },
        {
          label: 'Karus - Getijden Klas 4',
          value: 'Karus - Getijden Klas 4'
        },
        {
          label: 'Karus - Getijden Klas 5',
          value: 'Karus - Getijden Klas 5'
        },
        {
          label: 'Karus - Getijden Project',
          value: 'Karus - Getijden Project'
        },
        {
          label: 'Karus - Getijden Praktijk',
          value: 'Karus - Getijden Praktijk'
        },
        {
          label: 'Karus - Getijden STEM',
          value: 'Karus - Getijden STEM'
        },
        {
          label: 'Karus - Getijden Leraarskamer',
          value: 'Karus - Getijden Leraarskamer'
        },
        {
          label: 'Karus - Getijden Klastitularis',
          value: 'Karus - Getijden Klastitularis'
        },
        {
          label: 'Karus - Getijden Gangkast',
          value: 'Karus - Getijden Gangkast'
        },
        {
          label: 'Karus - Branding Praktijk',
          value: 'Karus - Branding Praktijk'
        },
        {
          label: 'Karus - Branding Klas',
          value: 'Karus - Branding Klas'
        },
        {
          label: 'Sint-Lucas',
          value: 'Sint-Lucas'
        },
        {
          label: 'UZ - 1K5',
          value: 'UZ - 1K5'
        },
        {
          label: 'UZ - 4K12D',
          value: 'UZ - 4K12D'
        },
        {
          label: 'UZ - 5K12D',
          value: 'UZ - 5K12D'
        },
        {
          label: 'UZ - ES',
          value: 'UZ - ES'
        },
        {
          label: 'UZ - K7',
          value: 'UZ - K7'
        },
        {
          label: 'UZ - KJP',
          value: 'UZ - KJP'
        },
        {
          label: 'UZ - KRC',
          value: 'UZ - KRC'
        }
      ],
      multiple: true,
      autocomplete: true,
      icon: 'icon-close',
      placeholder: 'Afdeling',
      classNames: multiSelectDropDownCss
    });
  } else {
    const userDepartmentValues = userDepartment.split(',');
    departmentDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'De Sleutel - RKJ',
          value: 'De Sleutel - RKJ'
        },
        {
          label: 'Guislain - De Steiger',
          value: 'Guislain - De Steiger'
        },
        {
          label: 'Guislain - Fioretti',
          value: 'Guislain - Fioretti'
        },
        {
          label: 'Karus - Branding',
          value: 'Karus - Branding'
        },
        {
          label: 'Karus - Dageraad',
          value: 'Karus - Dageraad'
        },
        {
          label: 'Karus - DAM',
          value: 'Karus - DAM'
        },
        {
          label: 'Karus - JOVO',
          value: 'Karus - JOVO'
        },
        {
          label: 'Karus - LG1',
          value: 'Karus - LG1'
        },
        {
          label: 'Karus - LG2',
          value: 'Karus - LG2'
        },
        {
          label: 'Karus - LG3',
          value: 'Karus - LG3'
        },
        {
          label: 'Karus - LG4',
          value: 'Karus - LG4'
        },
        {
          label: 'Karus - Kaap Klas 1',
          value: 'Karus - Kaap Klas 1'
        },
        {
          label: 'Karus - Kaap Klas 2',
          value: 'Karus - Kaap Klas 2'
        },
        {
          label: 'Karus - Kaap Klas 3',
          value: 'Karus - Kaap Klas 3'
        },
        {
          label: 'Karus - Kaap Klas 4',
          value: 'Karus - Kaap Klas 4'
        },
        {
          label: 'Karus - Kaap Klas 5',
          value: 'Karus - Kaap Klas 5'
        },
        {
          label: 'Karus - Kaap Leraarskamer',
          value: 'Karus - Kaap Leraarskamer'
        },
        {
          label: 'Karus - Kaap Knutselkast',
          value: 'Karus - Kaap Knutselkast'
        },
        {
          label: 'Karus - Getijden Klas 1',
          value: 'Karus - Getijden Klas 1'
        },
        {
          label: 'Karus - Getijden Klas 2',
          value: 'Karus - Getijden Klas 2'
        },
        {
          label: 'Karus - Getijden Klas 3',
          value: 'Karus - Getijden Klas 3'
        },
        {
          label: 'Karus - Getijden Klas 4',
          value: 'Karus - Getijden Klas 4'
        },
        {
          label: 'Karus - Getijden Klas 5',
          value: 'Karus - Getijden Klas 5'
        },
        {
          label: 'Karus - Getijden Project',
          value: 'Karus - Getijden Project'
        },
        {
          label: 'Karus - Getijden Praktijk',
          value: 'Karus - Getijden Praktijk'
        },
        {
          label: 'Karus - Getijden STEM',
          value: 'Karus - Getijden STEM'
        },
        {
          label: 'Karus - Getijden Leraarskamer',
          value: 'Karus - Getijden Leraarskamer'
        },
        {
          label: 'Karus - Getijden Klastitularis',
          value: 'Karus - Getijden Klastitularis'
        },
        {
          label: 'Karus - Getijden Gangkast',
          value: 'Karus - Getijden Gangkast'
        },
        {
          label: 'Karus - Branding Praktijk',
          value: 'Karus - Branding Praktijk'
        },
        {
          label: 'Karus - Branding Klas',
          value: 'Karus - Branding Klas'
        },
        {
          label: 'Sint-Lucas',
          value: 'Sint-Lucas'
        },
        {
          label: 'UZ - 1K5',
          value: 'UZ - 1K5'
        },
        {
          label: 'UZ - 4K12D',
          value: 'UZ - 4K12D'
        },
        {
          label: 'UZ - 5K12D',
          value: 'UZ - 5K12D'
        },
        {
          label: 'UZ - ES',
          value: 'UZ - ES'
        },
        {
          label: 'UZ - K7',
          value: 'UZ - K7'
        },
        {
          label: 'UZ - KJP',
          value: 'UZ - KJP'
        },
        {
          label: 'UZ - KRC',
          value: 'UZ - KRC'
        }
      ],
      value: userDepartmentValues,
      multiple: true,
      autocomplete: true,
      icon: 'icon-close',
      placeholder: 'Afdeling',
      classNames: multiSelectDropDownCss
    });
  }

  return departmentDropDown;
};

export const createVakDropDown = (dropdown, gameVak, meerVoudigeSelectie) => {
  let vakDropDown = '';

  if (
    gameVak === undefined ||
    gameVak === 'undefined' ||
    gameVak.trim().length === 0
  ) {
    vakDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Aardrijkskunde',
          value: 'Aardrijkskunde'
        },
        {
          label: 'ADL',
          value: 'ADL'
        },
        {
          label: 'Duits',
          value: 'Duits'
        },
        {
          label: 'Economie',
          value: 'Economie'
        },
        {
          label: 'Engels',
          value: 'Engels'
        },
        {
          label: 'Frans',
          value: 'Frans'
        },
        {
          label: 'Geschiedenis',
          value: 'Geschiedenis'
        },
        {
          label: 'ICT',
          value: 'ICT'
        },
        {
          label: 'Latijn',
          value: 'Latijn'
        }
        ,
        {
          label: 'Lichamelijke opvoeding',
          value: 'Lichamelijke opvoeding'
        },
        {
          label: 'Mens en maatschappij',
          value: 'Mens en maatschappij'
        },
        {
          label: 'Muzische vorming',
          value: 'Muzische vorming'
        },
        {
          label: 'Nederlands',
          value: 'Nederlands'
        },
        {
          label: 'PAV',
          value: 'PAV'
        },
        {
          label: 'Plastische vorming',
          value: 'Plastische vorming'
        },
        {
          label: 'Praktijk',
          value: 'Praktijk'
        },
        {
          label: 'Project',
          value: 'Project'
        },
        {
          label: 'Rijbewijs',
          value: 'Rijbewijs'
        },
        {
          label: 'Sociale wetenschappen',
          value: 'Sociale wetenschappen'
        },
        {
          label: 'STEM',
          value: 'STEM'
        },
        {
          label: 'Wetenschappen',
          value: 'Wetenschappen'
        },
        {
          label: 'Wiskunde',
          value: 'Wiskunde'
        }
      ],
      multiple: meerVoudigeSelectie,
      autocomplete: true,
      icon: 'icon-close',
      placeholder: 'Vakken',
      classNames: multiSelectDropDownCss
    });
  } else {
    const gameVakValues = gameVak.split(',');
    vakDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Aardrijkskunde',
          value: 'Aardrijkskunde'
        },
        {
          label: 'ADL',
          value: 'ADL'
        },
        {
          label: 'Duits',
          value: 'Duits'
        },
        {
          label: 'Economie',
          value: 'Economie'
        },
        {
          label: 'Engels',
          value: 'Engels'
        },
        {
          label: 'Frans',
          value: 'Frans'
        },
        {
          label: 'Geschiedenis',
          value: 'Geschiedenis'
        },
        {
          label: 'ICT',
          value: 'ICT'
        },
        {
          label: 'Latijn',
          value: 'Latijn'
        },
        {
          label: 'Lichamelijke opvoeding',
          value: 'Lichamelijke opvoeding'
        },
        {
          label: 'Mens en maatschappij',
          value: 'Mens en maatschappij'
        },
        {
          label: 'Muzische vorming',
          value: 'Muzische vorming'
        },
        {
          label: 'Nederlands',
          value: 'Nederlands'
        },
        {
          label: 'PAV',
          value: 'PAV'
        },
        {
          label: 'Plastische vorming',
          value: 'Plastische vorming'
        },
        {
          label: 'Praktijk',
          value: 'Praktijk'
        },
        {
          label: 'Project',
          value: 'Project'
        },
        {
          label: 'Rijbewijs',
          value: 'Rijbewijs'
        },
        {
          label: 'Sociale wetenschappen',
          value: 'Sociale wetenschappen'
        },
        {
          label: 'STEM',
          value: 'STEM'
        },
        {
          label: 'Wetenschappen',
          value: 'Wetenschappen'
        },
        {
          label: 'Wiskunde',
          value: 'Wiskunde'
        }
      ],
      value: gameVakValues,
      multiple: meerVoudigeSelectie,
      autocomplete: true,
      icon: 'icon-close',
      placeholder: 'Vakken',
      classNames: multiSelectDropDownCss
    });
  }

  return vakDropDown;
};

export const createKindGesprekDropDown = (dropdown, kindGesprek) => {
  let kindGesprekDropDown = '';

  if (
    kindGesprek === undefined ||
    kindGesprek === 'undefined' ||
    kindGesprek.trim().length === 0
  ) {
    kindGesprekDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Doelen',
          value: 'Doelen'
        },
        {
          label: 'Feedback',
          value: 'Feedback'
        },
        {
          label: 'Kennismaking',
          value: 'Kennismaking'
        },
        {
          label: 'Probleemverkenning',
          value: 'Probleemverkenning'
        }
      ],
      multiple: true,
      icon: 'icon-close',
      placeholder: 'Kindgesprek',
      classNames: multiSelectDropDownCss
    });
  } else {
    const kindGesprekValues = kindGesprek.split(',');

    kindGesprekDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Doelen',
          value: 'Doelen'
        },
        {
          label: 'Feedback',
          value: 'Feedback'
        },
        {
          label: 'Kennismaking',
          value: 'Kennismaking'
        },
        {
          label: 'Probleemverkenning',
          value: 'Probleemverkenning'
        }
      ],
      value: kindGesprekValues,
      multiple: true,
      icon: 'icon-close',
      placeholder: 'Kindgesprek',
      classNames: multiSelectDropDownCss
    });
  }

  return kindGesprekDropDown;
};

export const createIsolatieDropDown = (dropdown, gameIsolatie) => {
  let isolatieDropDown = '';
  if (gameIsolatie !== undefined) {
    isolatieDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Geschikt',
          value: 'true'
        },
        {
          label: 'Niet geschikt',
          value: 'false'
        }
      ],
      value: gameIsolatie,
      icon: 'icon-close',
      placeholder: 'Isolatie',
      classNames: multiSelectDropDownCss
    });
  } else {
    isolatieDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Geschikt',
          value: 'true'
        },
        {
          label: 'Niet geschikt',
          value: 'false'
        }
      ],
      icon: 'icon-close',
      placeholder: 'Isolatie',
      classNames: multiSelectDropDownCss
    });
  }

  return isolatieDropDown;
};

export const createUitleenbaarDropDown = (dropdown, gameUitleenbaar) => {
  let uitleenbaarDropDown = '';
  if (gameUitleenbaar !== undefined) {
    uitleenbaarDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Uitleenbaar',
          value: 'true'
        },
        {
          label: 'Niet uitleenbaar',
          value: 'false'
        }
      ],
      value: gameUitleenbaar,
      icon: 'icon-close',
      placeholder: 'Uitleenbaar',
      classNames: multiSelectDropDownCss
    });
  } else {
    uitleenbaarDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Uitleenbaar',
          value: 'true'
        },
        {
          label: 'Niet uitleenbaar',
          value: 'false'
        }
      ],
      icon: 'icon-close',
      placeholder: 'Uitleenbaar',
      classNames: multiSelectDropDownCss
    });
  }

  return uitleenbaarDropDown;
};

export const createLeerprofielDropDown = (dropdown, gameLeerprofiel) => {
  let leerprofielDropDown = '';

  if (
    gameLeerprofiel === undefined ||
    gameLeerprofiel === 'undefined' ||
    gameLeerprofiel.trim().length === 0
  ) {
    leerprofielDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Cognitief functioneren',
          value: 'Cognitief functioneren'
        },
        {
          label: 'Groepsfunctioneren',
          value: 'Groepsfunctioneren'
        },
        {
          label: 'Leer- en werkhouding',
          value: 'Leer- en werkhouding'
        },
        {
          label: 'Leerbereidheid',
          value: 'Leerbereidheid'
        },
        {
          label: 'Lichamelijk functioneren',
          value: 'Lichamelijk functioneren'
        }
      ],
      multiple: true,
      icon: 'icon-close',
      placeholder: 'Leerprofiel',
      classNames: multiSelectDropDownCss
    });
  } else {
    const gameLeerprofielValues = gameLeerprofiel.split(',');
    leerprofielDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Cognitief functioneren',
          value: 'Cognitief functioneren'
        },
        {
          label: 'Groepsfunctioneren',
          value: 'Groepsfunctioneren'
        },
        {
          label: 'Leer- en werkhouding',
          value: 'Leer- en werkhouding'
        },
        {
          label: 'Leerbereidheid',
          value: 'Leerbereidheid'
        },
        {
          label: 'Lichamelijk functioneren',
          value: 'Lichamelijk functioneren'
        }
      ],
      value: gameLeerprofielValues,
      multiple: true,
      icon: 'icon-close',
      placeholder: 'Leerprofiel',
      classNames: multiSelectDropDownCss
    });
  }
  return leerprofielDropDown;
};

export const createExecutieveFunctieDropDown = (dropdown, executieveFunctie) => {
  let executieveFunctieDropDown = '';

  if (
    executieveFunctie === undefined ||
    executieveFunctie === 'undefined' ||
    executieveFunctie.trim().length === 0
  ) {
    executieveFunctieDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Flexibiliteit',
          value: 'Flexibiliteit'
        },
        {
          label: 'Inhibitie',
          value: 'Inhibitie'
        },
        {
          label: 'Doelgericht gedrag',
          value: 'Doelgericht gedrag'
        },
        {
          label: 'Plannen',
          value: 'Plannen'
        }
        ,
        {
          label: 'Timemanagement',
          value: 'Timemanagement'
        }
        ,
        {
          label: 'Werkgeheugen',
          value: 'Werkgeheugen'
        }
        ,
        {
          label: 'Volgehouden aandacht',
          value: 'Volgehouden aandacht'
        }
        ,
        {
          label: 'Emotieregulatie',
          value: 'Emotieregulatie'
        }
        ,
        {
          label: 'Organiseren',
          value: 'Organiseren'
        }
      ],
      multiple: true,
      icon: 'icon-close',
      placeholder: 'Executieve Functie',
      classNames: multiSelectDropDownCss
    });
  } else {
    const executieveFunctieValues = executieveFunctie.split(',');

    executieveFunctieDropDown = new SelectPure(dropdown, {
      options: [
        {
          label: 'Flexibiliteit',
          value: 'Flexibiliteit'
        },
        {
          label: 'Inhibitie',
          value: 'Inhibitie'
        },
        {
          label: 'Doelgericht gedrag',
          value: 'Doelgericht gedrag'
        },
        {
          label: 'Plannen',
          value: 'Plannen'
        }
        ,
        {
          label: 'Timemanagement',
          value: 'Timemanagement'
        }
        ,
        {
          label: 'Werkgeheugen',
          value: 'Werkgeheugen'
        }
        ,
        {
          label: 'Volgehouden aandacht',
          value: 'Volgehouden aandacht'
        }
        ,
        {
          label: 'Emotieregulatie',
          value: 'Emotieregulatie'
        }
        ,
        {
          label: 'Organiseren',
          value: 'Organiseren'
        }
      ],
      value: executieveFunctieValues,
      multiple: true,
      icon: 'icon-close',
      placeholder: 'Executieve Functie',
      classNames: multiSelectDropDownCss
    });
  }

  return executieveFunctieDropDown;
};
